<template>
<div class="w-100">
  <EmailTemplate :isLoading="isLoading"   :emailTemplateDetail="emailTemplateDetail"></EmailTemplate>
  
</div>
</template>
<script>

import EmailTemplate from './EmailTemplate.vue';
import { mapActions } from 'vuex';
export default {

   components:{
    EmailTemplate,
   },
   data(){
    return {

      emailTemplateDetail:{
        name:'',
        selectedEmailTemplateId:null
      },
errors:{
    "name": "",
    emailTemplate:''

},
isLoading:false,
    }
   },

   computed:{
    isValid(){
      return this.emailTemplateDetail.name != "" && this.emailTemplateDetail.selectedEmailTemplateId;
    }
   },
  
   methods:{
    ...mapActions(['fetchData']),

      validateEmailDetails() {
      this.errors = {}; // Clear previous errors
      // Validation logic
      if (!this.emailTemplateDetail.name) {
        this.errors.name = "required";

        this.$toasted.show("Name is required", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }

      if(!this.emailTemplateDetail.selectedEmailTemplateId){
        this.errors.emailTemplate = "required"
        this.$toasted.show("Select an email template to continue", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
      

      if (Object.keys(this.errors).length === 0) {
       
        return  true;
      }
      return false
    },
    
   async  store(){

    try {
      if(!this.validateEmailDetails()){
       return 
      }
      this.$emit('startLoading')

    
      const data =await this.fetchData({
      axiosMethod:'post',
      resource:'email_templates',
      method:'store',
      params:this.emailTemplateDetail
    })
    
     if(data)
     {
      
      this.$router.push('/manage/emails/templates')
      // window.open('/EmailEditor/'+data.data.id, "_blank");
 this.toast('Email Created');
            this.$emit('stopLoading',false)
            return 
     }
    } catch (error) {
      if(error){
        this.toast('Error occured!')
      }
    } finally{
      this.$emit('stopLoading',true)
    }

     
    
   
    
    
   
      
        
    },
    toast(text){
          this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
    }
   }
}
</script>